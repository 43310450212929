import { useState, useEffect } from 'react';
import moment from 'moment';
import {sortBy} from 'lodash';

const useGigs = () => {
  const [loading, setLoading] = useState(true);
  const [gigs, setGigs] = useState([]);

  const CAL_ID = 'c9ebdc483ad3f2482a878bf51b07f532376369f9463a5ba6534f750a9108c088@group.calendar.google.com';
  const API_KEY = 'AIzaSyAuzUPbM7QeWIqa85aDF48KCRG4lLUTaTs';

  useEffect(() => {
    // https://developers.google.com/calendar/api/v3/reference/events/list
    fetch(
      `https://www.googleapis.com/calendar/v3/calendars/${CAL_ID}/events?key=${API_KEY}&timeMin=${moment().tz('America/Los_Angeles').startOf('day').format()}&timeZone=America/Los_Angeles`
    )
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data);
        setGigs(sortBy(data.items,(a)=> a?.start?.dateTime ));
      })
      .catch((error) => {
        console.error('Error:', error);
      }).finally(() => {
        setLoading(false);
      });
  }, []);

  return { gigs, loading };
};

export default useGigs;
