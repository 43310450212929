import React from 'react';
import useGigs from '../../hooks/useGigs';
import moment from 'moment-timezone';

const Gigs = () => {
  const { gigs, loading } = useGigs();

  if( loading ) {
    return <h2>Loading...</h2>
  }

  if( !gigs.length ) {
    return <h2>No Upcoming Gigs</h2>
  }

  return (
    <div>
      <h3>Upcoming Gigs</h3>

      {gigs.map(( gig )=>{
        return(
          <div key={gig.id} style={{margin: '20px 0'}}>
            <h5 style={{marginBottom: 5}}><a href={`http://maps.google.com/?q=${gig.summary} - ${gig.location}`} target='_blank'>{gig.summary} - {gig.location}</a></h5>
            <p>{moment(gig.start.dateTime).tz('America/Los_Angeles').format('MMMM Do YYYY, h:mm')}-{moment(gig.end.dateTime).tz('America/Los_Angeles').format('h:mma')}</p>
          </div>
        )
      })}
    </div>
  );
};

export default Gigs;
